import QRCodeModule from "react-qr-code";

// Styles
import styles from './styles/QRCode.module.scss'

// Images
import closeIcon from './images/close.svg'
import PinIcon from './images/Pin-Icon.svg'
import DownArrow from './images/DownArrow.svg'


function QRCode (props) {
    const {url, close, name} = props;

    function popupCenter(url, title, w, h) {
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft =
          window.screenLeft !== undefined ? window.screenLeft : window.screen.left
        var dualScreenTop =
          window.screenTop !== undefined ? window.screenTop : window.screen.top
    
        let width = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : window.screen.width
        let height = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : window.screen.height
    
        var left = width / 2 - w / 2 + dualScreenLeft
        var top = height / 2 - h / 2 + dualScreenTop
        var newWindow = window.open(
          url,
          title,
          "scrollbars=yes, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
        )
    
        // Puts focus on the newWindow
        if (window.focus) {
          newWindow.focus()
        }
    }

    return (
        <div className={styles.QRCodeContainer} onClick={close}>
            <div className={styles.QRCode}>
                <h2 className={styles.Name}>
                    Scan Me
                    <img className={styles.DownArrow} src={DownArrow}/>
                </h2>
                <div className={styles.QRCodeInner}>
                    <QRCodeModule value={url} size={150}/>
                </div>
                <div className={styles.Text}>
                    <p>Scan the QR code on your device to open up this fantastic development on your device</p>          
                    <p className={styles.Disclaimer}>Please note, mobile tariffs may apply.</p>
                    <button
                        onClick={() => popupCenter(url, name, "900", "500")}
                        className={styles.Button}
                    >
                        View on screen
                    </button>
                </div>
                <img className={styles.PinIcon} src={PinIcon}/>
                <img onClick={close} className={styles.Close} src={closeIcon}/>
            </div>
        </div>
    )

}

export default QRCode