import { useState } from "react";

export default function useRegionFilter(developmentData, clientFilter) {
  const [regionFilter, setRegionFilter] = useState(
    "Where are you looking to move?"
  );

  const clientDevelopmentData =
    clientFilter !== "All Developers"
      ? developmentData.filter((record) => {
          return record["development"]["client"] === clientFilter;
        })
      : developmentData;

  // Create List of all region fitlers
  const regionFilters = [
    ...new Set([
      "Where are you looking to move?",
      ...clientDevelopmentData.map((record) => {
        return record["development"]["region"]
          ? `${record["development"]["region"]}`
          : "Where are you looking to move?";
      }),
    ]),
  ];
  regionFilters.sort();

  // 'Move Where are you looking to move?' to index 0. Messy but added added retrospectively
  let allRegionsIndex = null;
  regionFilters.forEach((filter, index) => {
    if (filter === "Where are you looking to move?") {
      allRegionsIndex = index;
    }
  });
  regionFilters.splice(allRegionsIndex, 1);
  regionFilters.unshift("Where are you looking to move?");

  return [regionFilter, setRegionFilter, regionFilters];
}
