// Packages
import React, { useState } from "react";

// Components
import Board from "./Board";
import MapContainer from "./MapContainer";
import QRCode from "./QRCode";
import ShowHomeModal from "./ShowHomeModal";
import Dropdown from "./Dropdown";

// Styles
import styles from "./styles/App.module.scss";
import "./styles/global.scss";

// Images
import pinIcon from "./images/GreyPinIcon.svg";
import clientIcon from "./images/home-switch.svg";
import houseIcon from "./images/GreyHouse.svg";
import ShowHomeSwitch from "./ShowHomeSwitch";
import logoRedrow from "./images/redrow-logo.png";
import logoBarratt from "./images/barratt-logo.png";
import logoBarrattLondon from "./images/barratt-london-logo.png";
import logoDWH from "./images/dwh-logo.png";

// Hooks
import useDevelopmentData from "./hooks/useDevelopmentData";
import useRedrowTypeData from "./hooks/useRedrowTypeData";
import useRegionFilter from "./hooks/useRegionFilter";
import useShowHomeFilter from "./hooks/useShowHomeFilter";
import useClientFilter from "./hooks/useClientFilter";

function App() {
  const [redrowappData, redrowappError] = useDevelopmentData();
  const [redrowPlotTypeData] = useRedrowTypeData();
  const [clientFilter, clientFilters, setClientFilter] =
    useClientFilter(redrowappData);
  const [regionFilter, setRegionFilter, regionFilters] = useRegionFilter(
    redrowappData,
    clientFilter
  );
  const [
    showHomeFilter,
    setShowHomeFilter,
    showHomeFilters,
    showShowHomesFilter,
    setShowShowHomesFilter,
  ] = useShowHomeFilter(redrowappData, redrowPlotTypeData, regionFilter);

  const updateClient = (client) => {
    setClientFilter(client);
    setShowHomeFilter("Discover housetypes");
    setRegionFilter("Where are you looking to move?");
    setShowShowHomesFilter(false);
  };

  const clientLogo = (() => {
    switch (clientFilter) {
      case "Redrow":
        return logoRedrow;
      case "Barratt Homes":
        return logoBarratt;
      case "Barratt London":
        return logoBarrattLondon;
      case "David Wilson Homes":
        return logoDWH;
      default:
        return null;
    }
  })();

  const clientLogoBg = (() => {
    switch (clientFilter) {
      case "Barratt Homes":
        return "#a3ca35";
      case "David Wilson Homes":
        return "#002a4e";
      case "Barratt London":
        return "#9ecabe";
      default:
        return "#fff";
    }
  })();

  const [qrCode, setQrcode] = useState(null);
  const [showHomeModal, setShowHomeModal] = useState(null);
  const [zoomedDevelopments, setZoomedDevelopments] = useState(null);

  if (!redrowappError) {
    return (
      <div className={styles.App}>
        <div className={styles.Body}>
          {clientLogo && (
            <div
              className={styles.Header}
              style={{ "--client-logo-bg": clientLogoBg }}
            >
              <img src={clientLogo} className={styles.Logo} />
            </div>
          )}
          <Board
            redrowappData={redrowappData}
            clientFilter={clientFilter}
            setRegionFilter={setRegionFilter}
            setShowHomeFilter={setShowHomeFilter}
            regionFilter={regionFilter}
            showHomeFilter={showHomeFilter}
            showShowHomes={showShowHomesFilter}
            setQrcode={setQrcode}
            setShowHomeModal={setShowHomeModal}
            showShowHomesFilter={showShowHomesFilter}
            zoomedDevelopments={zoomedDevelopments}
          />
          <div className={styles.DropdownContainer}>
            {clientFilter !== "All Developers" && (
              <Dropdown
                currentValue={regionFilter}
                setValue={setRegionFilter}
                values={regionFilters}
                icon={pinIcon}
                defaultValue={"Where are you looking to move?"}
              />
            )}
            {clientFilter === "Redrow" && (
              <div
                className={
                  regionFilter === "Where are you looking to move?"
                    ? styles.houseTypeContainer
                    : styles.houseTypeContainerDisabled
                }
              >
                <Dropdown
                  currentValue={showHomeFilter}
                  setValue={setShowHomeFilter}
                  values={showHomeFilters}
                  icon={houseIcon}
                  defaultValue={"Discover housetypes"}
                />
              </div>
            )}
            <Dropdown
              currentValue={clientFilter}
              setValue={updateClient}
              values={clientFilters}
              icon={clientIcon}
              defaultValue={"All Developers"}
            />
          </div>
          {clientFilter === "Redrow" && (
            <div className={styles.ShowHomeContainer}>
              <ShowHomeSwitch
                currentValue={showShowHomesFilter}
                setValue={setShowShowHomesFilter}
                values={regionFilters}
                icon={pinIcon}
                defaultValue={"Where are you looking to move?"}
              />
            </div>
          )}
          <MapContainer
            redrowappData={redrowappData}
            clientFilter={clientFilter}
            regionFilter={regionFilter}
            showHomeFilter={showHomeFilter}
            setQrcode={setQrcode}
            setShowHomeModal={setShowHomeModal}
            showShowHomesFilter={showShowHomesFilter}
            setZoomedDevelopments={setZoomedDevelopments}
          />
          {qrCode && (
            <QRCode
              url={qrCode.url}
              name={qrCode.name}
              close={() => setQrcode(null)}
            />
          )}
          {showHomeModal && (
            <ShowHomeModal
              data={showHomeModal}
              showHomeFilter={"test"}
              close={() => setShowHomeModal(null)}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.App}>
        <div className={styles.Error}>
          <h1>Error</h1>
          <p>{redrowappError.message}</p>
        </div>
      </div>
    );
  }
}

export default App;
