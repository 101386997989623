// Styles
import styles from "./styles/ShowHomeModal.module.scss";

// Images
import closeIcon from "./images/close.svg";
import PinIcon from "./images/Grey-Pin-Icon.svg";
import HomeIcon from "./images/Home-Icon.svg";
import PoundIcon from "./images/Pound-Icon.svg";

function priceFormatter(price) {
  let retVal = "£POA";

  if (parseInt(price) > 0) {
    let priceFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    retVal = priceFormatter.format(price).replace(/\D00$/, "");
  }

  return retVal;
}

function ShowHomeModal(props) {
  const { url, name, region, availableTypes, currentShowHome } = props.data;
  const close = props.close;

  const houseTypes = Object.entries(availableTypes).map((type, i) => {
    if (
      currentShowHome === undefined ||
      currentShowHome === "Discover housetypes"
    ) {
      if (type[1]["statuses"]["Available"]) {
        return (
          <div className={styles.HouseType} key={i}>
            <img
              className={styles.Thumbnail}
              src={type[1]["thumbnail"]}
              height="200"
              width="200"
            />
            <div className={styles.HouseTypeDetails}>
              <h3 className={styles.Name}>{type[0]}</h3>
              <p className={styles.Text}>
                <img className={styles.Icon} src={HomeIcon} />
                {type[1]["statuses"]["Available"] === 1
                  ? type[1]["statuses"]["Available"] + " home available"
                  : type[1]["statuses"]["Available"] + " homes available"}
              </p>
              {type[1]["from_price"] && (
                <p className={styles.Text}>
                  <img className={styles.Icon} src={PoundIcon} />
                  From {priceFormatter(type[1]["from_price"])}
                </p>
              )}
            </div>
          </div>
        );
      }
    } else {
      if (type[0].toLowerCase().indexOf(currentShowHome.toLowerCase()) > -1) {
        if (type[1]["statuses"]["Available"]) {
          return (
            <div className={styles.HouseTypeSingle} key={i}>
              <img
                className={styles.Thumbnail}
                src={type[1]["thumbnail"]}
                height="200"
                width="200"
              />
              <div className={styles.HouseTypeDetails}>
                <h3 className={styles.Name}>{type[0]}</h3>
                <p className={styles.Text}>
                  <img className={styles.Icon} src={HomeIcon} />
                  {type[1]["statuses"]["Available"] === 1
                    ? type[1]["statuses"]["Available"] + " home available"
                    : type[1]["statuses"]["Available"] + " homes available"}
                </p>
                {type[1]["from_price"] && (
                  <p className={styles.Text}>
                    <img className={styles.Icon} src={PoundIcon} />
                    From {priceFormatter(type[1]["from_price"])}
                  </p>
                )}
              </div>
            </div>
          );
        }
      }
    }
  });

  const numberOfAvailableTypes = () => {
    let available = 0;
    if (
      currentShowHome === undefined ||
      currentShowHome === "Discover housetypes"
    ) {
      Object.entries(availableTypes).forEach((type) => {
        if (type[1]["statuses"]["Available"]) {
          available += type[1]["statuses"]["Available"];
        }
      });
    } else {
      Object.entries(availableTypes)
        .filter(
          (e) => e[0].toLowerCase().indexOf(currentShowHome.toLowerCase()) > -1
        )
        .forEach((type) => {
          if (type[1]["statuses"]["Available"]) {
            available += type[1]["statuses"]["Available"];
          }
        });
    }
    return available;
  };

  const viewHomesClicked = () => {
    window.parent.postMessage(
      {
        message: `${url}homes`,
      },
      "*"
    );
  };

  return (
    <div className={styles.ShowHomeModalContainer}>
      <div className={styles.ShowHomeModal}>
        <h2 className={styles.Name}>Redrow at {name}</h2>
        <p className={styles.Text}>
          <img className={styles.Icon} src={PinIcon} />
          {region}
        </p>

        <div className={styles.HouseTypesContainer}>{houseTypes}</div>

        <a
          href={`${url}homes`}
          className={styles.Button}
          onClick={() => viewHomesClicked()}
        >
          View all homes
        </a>
        <img onClick={close} className={styles.Close} src={closeIcon} />
        {numberOfAvailableTypes() > 0 && (
          <div className={styles.TypesCounter}>
            <p>{numberOfAvailableTypes()}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowHomeModal;
