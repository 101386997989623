import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default function useRedrowTypeData() {
  const [redrowPlotTypeData, setRedrowPlotTypeData] = useState([]);
  const [redrowPlotTypeError, setRedrowPlotTypeError] = useState(null);

  const getRedrowPlotTypes = () => {
    const urlPlotTypes =
      "https://assets.redrowapp.co.uk/collate/collatePlotTypes.php?task=master&key=F49JHD5EJhv7ay5S";

    axios
      .get(`${urlPlotTypes}`)
      .then((response) => {
        setRedrowPlotTypeData(
          response.data
            .map((record) => {
              // Ensure each dev has a unique id
              record["plotTypes"]["id"] = uuidv4();
              return record;
            })
            .sort((recordA, recordB) => {
              return recordA["plotTypes"]["plotType"] <
                recordB["plotTypes"]["plotType"]
                ? -1
                : recordA["plotTypes"]["plotType"] >
                  recordB["plotTypes"]["plotType"]
                ? 1
                : 0;
            })
        );
      })
      .catch((error) => {
        setRedrowPlotTypeError(error);
      });
  };

  useEffect(() => {
    if (!redrowPlotTypeData.length) {
      getRedrowPlotTypes();
    }
  }, []);

  return [redrowPlotTypeData, redrowPlotTypeError];
}
