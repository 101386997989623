import { useState } from "react";

export default function useClientFilter(redrowappData) {
  const [clientFilter, setClientFilter] = useState("All Developers");

  const clientFilters = [
    "All Developers",
    ...new Set(
      redrowappData.map((record) => {
        return record["development"]["client"];
      })
    ),
  ];

  return [clientFilter, clientFilters, setClientFilter];
}
