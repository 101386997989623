// Packages
import React, { useState, useEffect } from "react";
import { Marker, InfoBox, OverlayView } from "@react-google-maps/api";

// Styles
import styles from "./styles/MapMarker.module.scss";

// Images
import mapMarkerRedrow from "./images/map-marker.svg";
import mapMarkerBarrat from "./images/map-marker-barratt.svg";
import mapMarkerBarratLondon from "./images/map-marker-barratt-london.svg";
import mapMarkerDWH from "./images/map-marker-dwh.svg";

function MapMarker(props) {
  const {
    position,
    devName,
    id,
    showInfoBox = false,
    toogleInfoBox,
    currentRegion,
    region,
    previousDev,
    currentShowHome,
    showHomes,
    clusterer,
    url,
    setQrcode,
    setShowHomeModal,
    availableTypes,
    showShowHomesFilter,
    client,
    clientFilter,
  } = props;

  const mapMarker = (() => {
    switch (client) {
      case "Barratt Homes":
        return mapMarkerBarrat;
      case "Barratt London":
        return mapMarkerBarratLondon;
      case "David Wilson Homes":
        return mapMarkerDWH;
      default:
        return mapMarkerRedrow;
    }
  })();

  const clientColor = (() => {
    switch (client) {
      case "Barratt Homes":
        return "#a3ca35";
      case "Barratt London":
        return "#9ecabe";
      case "David Wilson Homes":
        return "#002a4e";
      default:
        return "#ca0928";
    }
  })();

  const onClick = () => {
    toogleInfoBox(id);
  };

  const houseTypeSelected = () => {
    if (currentShowHome === "Discover housetypes") {
      return true;
    }

    let shouldShow = showShowHomesFilter ? false : true;
    if (showHomes && showShowHomesFilter) {
      showHomes.forEach((showhome) => {
        if (
          showhome
            .replace("The ", "")
            .toLowerCase()
            .indexOf(currentShowHome.replace("The ", "").toLowerCase()) > -1
        ) {
          shouldShow = true;
        }
      });
    }
    return shouldShow;
  };

  const showHomeSelected = () => {
    if (showShowHomesFilter) {
      let shouldShow = false;
      if (showHomes) {
        if (Object.entries(showHomes).length > 0) shouldShow = true;
      }
      return shouldShow;
    } else {
      return true;
    }
  };

  const regionSelected = () => {
    if (
      region === currentRegion ||
      currentRegion === "Where are you looking to move?"
    ) {
      return true;
    }
    return false;
  };

  const clientSelected = () => {
    if (clientFilter === client || clientFilter === "All Developers") {
      return true;
    }
    return false;
  };

  const marker = (
    <Marker
      icon={{
        url: mapMarker,
        scale: 1,
      }}
      position={position}
      onClick={onClick}
      visible={
        showHomeSelected() &&
        regionSelected() &&
        houseTypeSelected() &&
        clientSelected()
          ? true
          : false
      }
      clusterer={clusterer}
    />
  );

  const infoBox = !previousDev ? (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => {
        return { x: -(width / 2) + 1, y: -(height * 2 + 13) };
      }}
    >
      <div
        className={styles.ToolTip}
        style={{ "--tooltip-bg": clientColor }}
        onClick={() => {
          if (client !== "Redrow") return;
          setShowHomeModal({ name: devName, url, region, availableTypes });
        }}
      >
        {devName}
      </div>
    </OverlayView>
  ) : window.innerWidth < 1000 ? (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => {
        return { x: -(width / 2) + 1, y: -(height * 2 + 13) };
      }}
    >
      <a
        className={styles.PreviousToolTip}
        style={{ "--tooltip-bg": clientColor(client) }}
        href={url}
      >
        {devName}
      </a>
    </OverlayView>
  ) : (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) => {
        return { x: -(width / 2) + 1, y: -(height * 2 + 13) };
      }}
    >
      <div
        className={styles.PreviousToolTip}
        onClick={() => setQrcode({ name: devName, url })}
      >
        {devName}
      </div>
    </OverlayView>
  );

  return (
    <>
      {marker}
      {showInfoBox && infoBox}
    </>
  );
}

export default MapMarker;
