// Packages
import React, { useState, useEffect } from 'react';

// Styles
import styles from './styles/Dropdown.module.scss'

// Images
import ResetIcon from './images/reset.svg'

function Dropdown (props) {

    const {currentValue, values, setValue, icon, defaultValue} = props

    const handleChange = e => {
        setValue(e.target.value)
    }

    const options = values.map(option => {
        return <option className={styles.Option} key={option} value={option}>{option}</option>
    })

    return (
        <form className={styles.Dropdown}>
          <label className={styles.Label}>
            {icon != null && <img className={styles.Icon} src={icon}/>}
            <select className={styles.Select} value={currentValue} onChange={handleChange}>
                {options}
            </select>
          </label>
          {currentValue !== defaultValue &&
            <button className={styles.ResetButton} onClick={(e) => {
              e.preventDefault()
              setValue(defaultValue)
            }}>
              <img src={ResetIcon} alt="Reset Filter"/>
            </button>
          }
        </form>
      );

}

export default Dropdown