// Styles
import styles from './styles/BreadcrumbMenu.module.scss'


function BreadcrumbMenu ({currentRegion}) {

    if(currentRegion === "Where are you looking to move?") {
        return ( 
            <div className={styles.BreadcrumbMenu}>
                Our Developments /  
                <span className={styles.Region}> {"All"}</span>
            </div>
        )
    }

    return ( 
        <div className={styles.BreadcrumbMenu}>
            Our Developments /  
            <span className={styles.Region}> {currentRegion}</span>
        </div>
    )

}

export default BreadcrumbMenu