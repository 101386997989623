import React from "react";

// Styles
import styles from "./styles/DevCard.module.scss";

// Images
import ArrowRedrow from "./images/Right-Arrow.svg";
import ArrowRedrowBarratt from "./images/Right-Arrow-barratt.svg";
import ArrowRedrowBarrattLondon from "./images/Right-Arrow-barrat-london.svg";
import ArrowRedrowBDW from "./images/Right-Arrow-dwh.svg";
import CheckMark from "./images/Check-Mark.svg";
import GreyHouse from "./images/GreyHouse.svg";

const clientColors = {
  "Barratt Homes": "#a3ca35",
  "David Wilson Homes": "#002a4e",
  "Barratt London": "#9ecabe",
};

function DevCard(props) {
  const {
    url,
    name,
    region,
    currentRegion,
    addressTagline,
    postcode,
    currentShowHome,
    isShowHome,
    types,
    client,
    clientFilter,
  } = props;

  const mapMarker = (() => {
    switch (client) {
      case "Barratt Homes":
        return ArrowRedrowBarratt;
      case "Barratt London":
        return ArrowRedrowBarrattLondon;
      case "David Wilson Homes":
        return ArrowRedrowBDW;
      default:
        return ArrowRedrow;
    }
  })();

  if (clientFilter !== "All Developers" && clientFilter !== client) return null;

  let numberOfAvailable = 0;
  if (currentShowHome === "Discover housetypes") {
    for (const _key in types) {
      if (types[_key]["statuses"]["Available"]) {
        numberOfAvailable += types[_key]["statuses"]["Available"];
      }
    }
  } else {
    for (const _key in types) {
      if (
        _key
          .replace("The ", "")
          .toLowerCase()
          .indexOf(currentShowHome.replace("The ", "").toLowerCase()) > -1
      ) {
        if (types[_key]["statuses"]["Available"]) {
          numberOfAvailable =
            numberOfAvailable + types[_key]["statuses"]["Available"] ?? 0;
        }
      }
    }
  }

  const regionSelected = () => {
    if (
      region === currentRegion ||
      currentRegion === "Where are you looking to move?"
    ) {
      return true;
    }
    return false;
  };
  return (
    <div
      href={url}
      className={regionSelected() ? styles.DevCard : styles.Hide}
      style={{ "--client-color": clientColors[client] ?? "#000" }}
      onClick={() => window.open(url, "popup", "width=1200,height=800")}
    >
      <div className={styles.DevCardDotRed}></div>
      <div className={styles.Text}>
        <h2 className={styles.Title}>{name}</h2>
        <p>{`${addressTagline}${postcode ? `, ${postcode}` : ""}`}</p>
        <div className={styles.DevCardStatus}>
          {numberOfAvailable > 0 ? (
            <p className={styles.DevCardAvailability}>
              <img className={styles.CheckMark} src={CheckMark} alt={name} />
              {numberOfAvailable} Available
            </p>
          ) : null}
          {isShowHome ? (
            <p className={styles.DevCardShowhome}>
              <img className={styles.GreyHouse} src={GreyHouse} alt={name} />
              Showhome
            </p>
          ) : null}
        </div>
      </div>
      <div className={styles.Cta}>
        <p>View</p>
        <img className={styles.Arrow} src={mapMarker} alt={name} />
      </div>
    </div>
  );
}

export default DevCard;
