// Packages
import React, { useState, useEffect } from "react";

// Styles
import styles from "./styles/ShowHomeSwitch.module.scss";

// Images
import ResetIcon from "./images/reset.svg";
import { FormCheck } from "react-bootstrap";

function ShowHomeSwitch(props) {
  const { currentValue, values, setValue, icon, defaultValue } = props;
  const [showShowHome, setShowShowHomes] = useState(null);

  useEffect(() => {
    if (currentValue === "Discover housetypes") {
      setShowShowHomes(false);
      setValue(false);
    }
  }, [currentValue]);

  const handleChangeOff = (e) => {
    setShowShowHomes(true);
    setValue(false);
  };

  const handleChangeOn = (e) => {
    setShowShowHomes(false);
    setValue(true);
  };

  return (
    <form className={styles.ShowHomeSwitch}>
      <label className={styles.Label}>
        {!showShowHome ? (
          <div style={{ display: "flex" }}>
            <p
              style={{
                width: "130px",
                margin: "0px",
                alignSelf: "center",
                paddingTop: "6px",
              }}
            >
              Available Homes
            </p>
            <FormCheck
              className={styles.formSwitch}
              type="switch"
              id="custom-switch"
              label="Show Homes"
              value={false}
              checked={currentValue}
              onChange={(e) => handleChangeOff(e)}
            ></FormCheck>
          </div>
        ) : null}
        {showShowHome ? (
          <div style={{ display: "flex" }}>
            <p
              style={{
                width: "130px",
                margin: "0px",
                alignSelf: "center",
                paddingTop: "6px",
              }}
            >
              Available Homes
            </p>
            <FormCheck
              className={styles.formSwitch}
              type="switch"
              id="custom-switch"
              label="Show Homes"
              value={true}
              checked={currentValue}
              onChange={(e) => handleChangeOn(e)}
            ></FormCheck>
          </div>
        ) : null}
      </label>
    </form>
  );
}

export default ShowHomeSwitch;
