// Packages
import React from "react";

// Components
import Loading from "./Loading";
import RedrowDevCard from "./RedrowDevCard";
import DevCard from "./DevCard";
import BreadcrumbMenu from "./BreadcrumbMenu";

// Styles
import styles from "./styles/Board.module.scss";

const clientColors = {
  "Barratt Homes": "#a3ca35",
  "David Wilson Homes": "#002a4e",
  "Barratt London": "#9ecabe",
  Redrow: "#ca0928",
};

function Board({
  redrowappData,
  clientFilter,
  regionFilter,
  showHomeFilter,
  setQrcode,
  setShowHomeModal,
  showShowHomesFilter,
  zoomedDevelopments,
}) {
  const developments = zoomedDevelopments ? zoomedDevelopments : redrowappData;

  const devComponents = developments.map((record) => {
    return record["development"]["client"] === "Redrow" ? (
      <RedrowDevCard
        key={record["development"]["id"]}
        name={record["development"]["name"]}
        url={record["development"]["URL"]}
        region={record["development"]["region"]}
        addressTagline={record["development"]["address_tagline"]}
        previousDev={record["development"]["legacy_development"]}
        currentRegion={regionFilter}
        currentShowHome={showHomeFilter}
        showHomes={record["development"]["showhomes"] ?? []}
        postcode={record["development"]["postcode"]}
        setQrcode={setQrcode}
        setShowHomeModal={setShowHomeModal}
        availableTypes={record["development"]["types"]}
        isShowHome={record["development"]["showhomes"]?.length ? true : false}
        showShowHomesFilter={showShowHomesFilter}
        types={record["development"]["types"]}
        clientFilter={clientFilter}
      />
    ) : (
      <DevCard
        key={record["development"]["id"]}
        name={record["development"]["name"]}
        url={record["development"]["URL"]}
        region={record["development"]["region"]}
        addressTagline={record["development"]["address_tagline"]}
        currentRegion={regionFilter}
        currentShowHome={showHomeFilter}
        postcode={record["development"]["postcode"]}
        isShowHome={record["development"]["showhomes"]?.length ? true : false}
        types={record["development"]["types"]}
        client={record["development"]["client"]}
        clientFilter={clientFilter}
      />
    );
  });

  return (
    <div
      className={styles.Board}
      style={{ "--heading-client-color": clientColors[clientFilter] ?? "#444" }}
    >
      {!redrowappData.length ? (
        <>
          <div className={styles.TextContainer}>
            <h1 className={styles.Title}>Explore</h1>
            <h2 className={styles.SubTitle}>
              {clientFilter === "All Developers" ? "Our" : clientFilter}{" "}
              Developments
            </h2>
          </div>
          <div className="site-loader">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <div className={styles.TextContainer}>
            <h1 className={styles.Title}>Explore</h1>
            <h2 className={styles.SubTitle}>
              {clientFilter === "All Developers" ? "Our" : clientFilter}{" "}
              Developments
            </h2>
          </div>
          <BreadcrumbMenu currentRegion={regionFilter} />
          <div className={styles.DevCards}>{devComponents}</div>
        </>
      )}
    </div>
  );
}

export default Board;
