import React, { Fragment, useState } from "react";

// Styles
import styles from "./styles/RedrowDevCard.module.scss";

// Components
import QRCode from "./QRCode";

// Images
import MagWhite from "./images/Mag-White.svg";
import MagRed from "./images/Mag-Red.svg";
import Arrow from "./images/Right-Arrow.svg";
import CheckMark from "./images/Check-Mark.svg";
import GreyHouse from "./images/GreyHouse.svg";

function RedrowDevCard(props) {
  const {
    url,
    name,
    region,
    currentRegion,
    addressTagline,
    postcode,
    previousDev,
    currentShowHome,
    showHomes,
    setQrcode,
    setShowHomeModal,
    availableTypes,
    isShowHome,
    types,
    showShowHomesFilter,
    clientFilter,
  } = props;

  if (clientFilter !== "All Developers" && clientFilter !== "Redrow")
    return null;

  let numberOfAvailable = 0;
  if (currentShowHome === "Discover housetypes") {
    for (const _key in types) {
      if (types[_key]["statuses"]["Available"]) {
        numberOfAvailable += types[_key]["statuses"]["Available"];
      }
    }
  } else {
    for (const _key in types) {
      if (
        _key
          .replace("The ", "")
          .toLowerCase()
          .indexOf(currentShowHome.replace("The ", "").toLowerCase()) > -1
      ) {
        if (types[_key]["statuses"]["Available"]) {
          numberOfAvailable =
            numberOfAvailable + types[_key]["statuses"]["Available"] ?? 0;
        }
      }
    }
  }

  if (numberOfAvailable === 0 && !showShowHomesFilter) return null;

  const houseTypeSelected = () => {
    if (currentShowHome === "Discover housetypes") {
      return true;
    }

    let shouldShow = showShowHomesFilter ? false : true;
    if (showHomes && showShowHomesFilter) {
      showHomes.forEach((showhome) => {
        if (
          showhome
            .toLowerCase()
            .indexOf(currentShowHome.replace("The ", "").toLowerCase()) > -1
        ) {
          shouldShow = true;
        }
      });
    }
    return shouldShow;
  };

  const showHomeSelected = () => {
    if (showShowHomesFilter) {
      let shouldShow = false;
      if (showHomes) {
        if (Object.entries(showHomes).length > 0) shouldShow = true;
      }
      return shouldShow;
    } else {
      return true;
    }
  };

  const regionSelected = () => {
    if (
      region === currentRegion ||
      currentRegion === "Where are you looking to move?"
    ) {
      return true;
    }
    return false;
  };

  if (!previousDev) {
    return (
      <a
        href={void 0}
        onClick={() =>
          setShowHomeModal({
            name,
            url,
            region,
            availableTypes,
            currentShowHome,
          })
        }
        className={
          showHomeSelected() && regionSelected() && houseTypeSelected()
            ? styles.RedrowDevCard
            : styles.Hide
        }
      >
        <div className={styles.RedrowDevCardDotRed}></div>
        <div className={styles.Text}>
          <h2 className={styles.Title}>{name}</h2>
          <p>{`${addressTagline}${postcode ? `, ${postcode}` : ""}`}</p>
          <div className={styles.RedrowDevCardStatus}>
            {numberOfAvailable > 0 ? (
              <p className={styles.RedrowDevCardAvailability}>
                <img className={styles.CheckMark} src={CheckMark} alt={name} />
                {numberOfAvailable} Available
              </p>
            ) : null}
            {isShowHome ? (
              <p className={styles.RedrowDevCardShowhome}>
                <img className={styles.GreyHouse} src={GreyHouse} alt={name} />
                Showhome
              </p>
            ) : null}
          </div>
        </div>
        <div className={styles.Cta}>
          <p>View</p>
          <img className={styles.Arrow} src={Arrow} alt={name} />
        </div>
      </a>
    );
  } else {
    if (window.innerWidth < 800) {
      return (
        <a
          href={url}
          className={
            showHomeSelected() && regionSelected() && houseTypeSelected()
              ? styles.RedrowDevCardPrevious
              : styles.Hide
          }
        >
          <div className={styles.Text}>
            <h2 className={styles.Title}>{name}</h2>
            <p>{`${addressTagline}${postcode ? `, ${postcode}` : ""}`}</p>
            <div className={styles.RedrowDevCardStatus}>
              {numberOfAvailable > 0 ? (
                <p className={styles.RedrowDevCardAvailability}>
                  {numberOfAvailable} Available
                </p>
              ) : null}
              {isShowHome ? (
                <p className={styles.RedrowDevCardShowhome}>Showhome</p>
              ) : null}
            </div>
          </div>
          <div className={styles.Cta}>
            <p>View</p>
            <img className={styles.Arrow} src={Arrow} alt={name} />
          </div>
        </a>
      );
    } else {
      return (
        <>
          <a
            href={void 0}
            onClick={() => setQrcode({ name, url })}
            className={
              showHomeSelected() && regionSelected() && houseTypeSelected()
                ? styles.RedrowDevCardPrevious
                : styles.Hide
            }
          >
            <div className={styles.RedrowDevCardDotGray}></div>
            <div className={styles.Text}>
              <h2 className={styles.Title}>{name}</h2>
              <p>{`${addressTagline}${postcode ? `, ${postcode}` : ""}`}</p>
              <div className={styles.RedrowDevCardStatus}>
                {numberOfAvailable > 0 ? (
                  <p className={styles.RedrowDevCardAvailability}>
                    {numberOfAvailable} Available
                  </p>
                ) : null}
                {isShowHome ? (
                  <p className={styles.RedrowDevCardShowhome}>Showhome</p>
                ) : null}
              </div>
            </div>
            <div className={styles.Cta}>
              <p>View</p>
              <img className={styles.Arrow} src={Arrow} alt={name} />
            </div>
          </a>
        </>
      );
    }
  }
}

export default RedrowDevCard;
