import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default function useDevelopmentData() {
  const [redrowappData, setRedrowappData] = useState([]);
  const [redrowappError, setRedrowappError] = useState(null);

  const getRedrowAppData = () => {
    const url =
      //   "https://assets.redrowapp.co.uk/collate/collate.php?task=master&key=F49JHD5EJhv7ay5S";
      "https://assets.redrowapp.co.uk/collate/JSON/master_barratt.json";

    axios
      .get(`${url}`)
      .then((response) => {
        setRedrowappData(
          response.data
            .map((record) => {
              // Ensure each dev has a unique id
              record["development"]["id"] = uuidv4();
              return record;
            })
            .sort((recordA, recordB) => {
              return recordA["development"]["name"] <
                recordB["development"]["name"]
                ? -1
                : recordA["development"]["name"] >
                  recordB["development"]["name"]
                ? 1
                : 0;
            })
        );
      })
      .catch((error) => {
        setRedrowappError(error);
      });
  };

  useEffect(() => {
    if (!redrowappData.length) {
      getRedrowAppData();
    }
  }, []);

  return [redrowappData, redrowappError];
}
