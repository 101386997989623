import { useState } from "react";

export default function useShowHomeFilter(
  redrowappData,
  redrowPlotTypeData,
  regionFilter
) {
  const [showHomeFilter, setShowHomeFilter] = useState("Discover housetypes");
  const [showShowHomesFilter, setShowShowHomesFilter] = useState(false);

  /* NOTE -- not sure if showhomes are in the new data?? -- !NOTE */
  // Create List of all Showhome fitlers
  const showHomeFilters = [
    ...new Set(
      ["Discover housetypes"].concat(
        ...redrowappData.map((record) => {
          if (!record["development"]["types"]) {
            return ["Discover housetypes"];
          }

          let _houseTypes = [];
          let allRegionsIndex = [];

          if (regionFilter === "Where are you looking to move?") {
            allRegionsIndex = record["development"]["types"];
          } else {
            if (record["development"]["region"] === regionFilter)
              allRegionsIndex = record["development"]["types"];
          }

          for (const _key in record["development"].types) {
            if (allRegionsIndex[_key]) {
              if (showShowHomesFilter) {
                for (const _keyPlot in redrowPlotTypeData) {
                  if (
                    _key
                      .replace("The ", "")
                      .toLowerCase()
                      .indexOf(
                        redrowPlotTypeData[_keyPlot]["plotTypes"]["plotType"]
                          .replace("The ", "")
                          .toLowerCase()
                      ) > -1
                  ) {
                    if (showShowHomesFilter) {
                      if (record["development"].showhomes) {
                        record["development"].showhomes.forEach((e) => {
                          if (
                            _key.toLowerCase().indexOf(e.toLowerCase()) > -1
                          ) {
                            _houseTypes.push(
                              "The " +
                                redrowPlotTypeData[_keyPlot]["plotTypes"][
                                  "plotType"
                                ]
                            );
                          }
                        });
                      }
                    } else {
                      _houseTypes.push(
                        "The " +
                          redrowPlotTypeData[_keyPlot]["plotTypes"]["plotType"]
                      );
                    }
                  }
                }
              } else {
                if (allRegionsIndex[_key]["statuses"]["Available"]) {
                  for (const _keyPlot in redrowPlotTypeData) {
                    if (
                      _key
                        .replace("The ", "")
                        .toLowerCase()
                        .indexOf(
                          redrowPlotTypeData[_keyPlot]["plotTypes"]["plotType"]
                            .replace("The ", "")
                            .toLowerCase()
                        ) > -1
                    ) {
                      if (showShowHomesFilter) {
                        if (record["development"].showhomes) {
                          record["development"].showhomes.forEach((e) => {
                            if (
                              _key.toLowerCase().indexOf(e.toLowerCase()) > -1
                            ) {
                              _houseTypes.push(
                                "The " +
                                  redrowPlotTypeData[_keyPlot]["plotTypes"][
                                    "plotType"
                                  ]
                              );
                            }
                          });
                        }
                      } else {
                        _houseTypes.push(
                          "The " +
                            redrowPlotTypeData[_keyPlot]["plotTypes"][
                              "plotType"
                            ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }

          return _houseTypes;
        })
      )
    ),
  ];
  showHomeFilters.sort();

  // Move 'Discover Showhomes' to index 0. Messy but added added retrospectively
  let allShowHomesIndex = null;
  showHomeFilters.forEach((filter, index) => {
    if (filter === "Discover housetypes") {
      allShowHomesIndex = index;
    }
  });
  showHomeFilters.splice(allShowHomesIndex, 1);
  showHomeFilters.unshift("Discover housetypes");

  return [
    showHomeFilter,
    setShowHomeFilter,
    showHomeFilters,
    showShowHomesFilter,
    setShowShowHomesFilter,
  ];
}
