// Packages
import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
} from "@react-google-maps/api";

// Components
import MapMarker from "./MapMarker";

// Styles
import styles from "./styles/MapContainer.module.scss";
import "./styles/MapClusterStyles.scss";

// Google Maps JS Styles
const containerStyle = {
  width: "100%",
  height: "100%",
};

// set styles for grayscale elements on the map
const grayscaleStylers = [
  { saturation: -100 },
  { gamma: 1.2 },
  { lightness: 4 },
];

const mapStyles = [
  {
    featureType: "administrative",
    stylers: [...grayscaleStylers],
  },
  {
    featureType: "landscape",
    stylers: [...grayscaleStylers],
  },
  {
    featureType: "road",
    stylers: [...grayscaleStylers],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    stylers: [...grayscaleStylers],
  },
  {
    featureType: "water",
    stylers: [...grayscaleStylers],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
const checkRecordLngLat = (redrowappData) => {
  // Turn lng, lat into single string so easier to compare
  const lngLatStrings = redrowappData.map((record) => {
    return `${record["development"]["latitude"]},${record["development"]["longitude"]}`;
  });

  // Find index of values where it's not the first occurrence of that lng,lat combination
  let duplicates = lngLatStrings.map((item, index) => {
    const firstOccurrence = lngLatStrings.indexOf(item);
    if (index !== firstOccurrence) {
      return { index, firstOccurrence };
    }
    return null;
  });

  // Find the 'index' of the duplicate since the first occurrence
  const numberOfDuplicatesAtFirstOccurrence = new Array(duplicates.length).fill(
    null
  );
  duplicates = duplicates.map((duplicate) => {
    if (duplicate !== null) {
      if (
        numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence] !== null
      ) {
        numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence] += 1;
      } else {
        numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence] = 0;
      }
      return {
        index: duplicate.index,
        firstOccurrence: duplicate.firstOccurrence,
        indexInDuplicates:
          numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence],
      };
    }
    return null;
  });
  // Then find the length of all instances of that duplicate
  duplicates = duplicates.map((duplicate) => {
    if (duplicate !== null) {
      if (
        numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence] !== null
      ) {
        return {
          index: duplicate.index,
          firstOccurrence: duplicate.firstOccurrence,
          indexInDuplicates: duplicate.indexInDuplicates,
          lengthOfDuplicates:
            numberOfDuplicatesAtFirstOccurrence[duplicate.firstOccurrence] + 1,
        };
      }
    }
    return null;
  });

  const offsetLngLats = redrowappData.map((record, i) => {
    if (duplicates[i] !== null) {
      const radius = 0.001;
      const lng =
        record["development"]["longitude"] +
        radius *
          Math.cos(
            (2 * Math.PI * duplicates[i].indexInDuplicates) /
              duplicates[i].lengthOfDuplicates
          );
      const lat =
        record["development"]["latitude"] +
        radius *
          Math.sin(
            (2 * Math.PI * duplicates[i].indexInDuplicates) /
              duplicates[i].lengthOfDuplicates
          );

      return {
        lng,
        lat,
      };
    }
    return {
      lng: record["development"]["longitude"],
      lat: record["development"]["latitude"],
    };
  });

  return offsetLngLats;
};

function MapContainer({
  redrowappData,
  clientFilter,
  regionFilter,
  showHomeFilter,
  setQrcode,
  setShowHomeModal,
  showShowHomesFilter,
  setZoomedDevelopments,
}) {
  const offsetLngLats = checkRecordLngLat(
    redrowappData.filter((record) => {
      return record["development"]["latitude"] &&
        record["development"]["longitude"]
        ? true
        : false;
    })
  );

  // Maps Loader
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB8XKQG2wreOLoUjyXhlmPdwKyAFsFaNUc",
  });

  // State
  const [map, setMap] = useState(null);
  const [devInfoBox, setDevInfoBox] = useState(null);

  const showHomeSelected = (showHomes) => {
    if (showHomeFilter === "Discover housetypes") {
      return true;
    }

    let shouldShow = false;
    if (showHomes) {
      Object.entries(showHomes).forEach((showhome) => {
        if (
          showhome[0]
            .replace("The ", "")
            .toLowerCase()
            .indexOf(showHomeFilter.replace("The ", "").toLowerCase()) > -1
        ) {
          shouldShow = true;
        }
      });
    }
    return shouldShow;
  };

  const regionSelected = (region) => {
    if (
      region === regionFilter ||
      regionFilter === "Where are you looking to move?"
    ) {
      return true;
    }
    return false;
  };

  const clientSelected = (client) => {
    if (clientFilter === client || clientFilter === "All Developers") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (map && redrowappData && !devInfoBox) {
      map.fitBounds(setBoundsNew());
      map.setZoom(setZoom(map));
    }
  });

  // Reset devInfoBox when region filter changes
  useEffect(() => {
    if (map && redrowappData) {
      map.fitBounds(setBoundsNew());
      map.setZoom(setZoom(map));
    }

    setDevInfoBox(null);
  }, [regionFilter, showHomeFilter, redrowappData]);

  const setBoundsNew = () => {
    const bounds = new window.google.maps.LatLngBounds();
    let noBounds = true;
    redrowappData
      .filter((record) => {
        return record["development"]["latitude"] &&
          record["development"]["longitude"]
          ? true
          : false;
      })
      .forEach((record, i) => {
        if (
          showHomeSelected(record["development"]["types"]) &&
          regionSelected(record["development"]["region"]) &&
          clientSelected(record["development"]["client"])
        ) {
          noBounds = false;
          bounds.extend({
            lat: offsetLngLats[i].lat,
            lng: offsetLngLats[i].lng,
          });
        }
      });

    if (noBounds) {
      bounds.extend({
        lat: 49.96965326815427,
        lng: -5.9432081725803085,
      });
      bounds.extend({
        lat: 56.12678123584949,
        lng: 1.6975168542019319,
      });
    }
    return bounds;
  };

  const setZoom = (map) => {
    const selectedDevs = redrowappData.filter((record) =>
      showHomeSelected(record["development"]["types"]) &&
      regionSelected(record["development"]["region"]) &&
      clientSelected(record["development"]["client"])
        ? true
        : false
    );
    if (selectedDevs.length === 1) {
      return 10;
    }

    if (selectedDevs.length === 0) {
      return 10;
    }

    return map.zoom;
  };

  // Maps Methods
  const onLoad = React.useCallback(function callback(map) {
    map.fitBounds(setBoundsNew());
    map.setZoom(setZoom(map));
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const toogleInfoBox = (devID) => {
    if (devInfoBox && devInfoBox === devID) setDevInfoBox(null);
    else setDevInfoBox(devID);
  };

  // Create Map Markers
  const devMarkers = (clusterer) =>
    redrowappData
      .filter((record) =>
        record["development"]["latitude"] && record["development"]["longitude"]
          ? true
          : false
      )
      .map((record, i) => {
        const showInfoBox =
          record["development"]["id"] === devInfoBox ? true : false;

        if (showHomeFilter === "Discover housetypes") {
          return (
            <MapMarker
              position={{
                lat: offsetLngLats[i].lat,
                lng: offsetLngLats[i].lng,
              }}
              devName={record["development"]["name"]}
              url={record["development"]["URL"]}
              key={record["development"]["id"]}
              id={record["development"]["id"]}
              previousDev={record["development"]["legacy_development"]}
              toogleInfoBox={toogleInfoBox}
              showInfoBox={showInfoBox}
              region={record["development"]["region"]}
              currentRegion={regionFilter}
              currentShowHome={showHomeFilter}
              showHomes={record["development"]["showhomes"]}
              clusterer={clusterer}
              setQrcode={setQrcode}
              setShowHomeModal={setShowHomeModal}
              availableTypes={record["development"]["types"]}
              showShowHomesFilter={showShowHomesFilter}
              client={record["development"]["client"]}
              clientFilter={clientFilter}
            />
          );
        } else {
          let _types = record["development"]["types"];
          let showType = false;

          for (const _key in _types) {
            if (
              _key
                .replace("The ", "")
                .toLowerCase()
                .indexOf(showHomeFilter.replace("The ", "").toLowerCase()) > -1
            ) {
              if (
                _types[_key]["statuses"]["Available"] ||
                showShowHomesFilter
              ) {
                showType = true;
              }
            }
          }

          if (showType) {
            return (
              <MapMarker
                position={{
                  lat: offsetLngLats[i].lat,
                  lng: offsetLngLats[i].lng,
                }}
                devName={record["development"]["name"]}
                url={record["development"]["URL"]}
                key={record["development"]["id"]}
                id={record["development"]["id"]}
                previousDev={record["development"]["legacy_development"]}
                toogleInfoBox={toogleInfoBox}
                showInfoBox={showInfoBox}
                region={record["development"]["region"]}
                currentRegion={regionFilter}
                currentShowHome={showHomeFilter}
                showHomes={record["development"]["showhomes"]}
                clusterer={clusterer}
                setQrcode={setQrcode}
                setShowHomeModal={setShowHomeModal}
                availableTypes={record["development"]["types"]}
                showShowHomesFilter={showShowHomesFilter}
                client={record["development"]["client"]}
                clientFilter={clientFilter}
              />
            );
          }
        }
      });

  const clusterOptions = {
    imagePath: "markerclusterer/m",
    imageSizes: [40, 40, 40, 40, 40, 40],
  };

  const zoomChanged = (map) => {
    if (map) {
      var _filteredPlots = [];
      const _bounds = map.getBounds();

      if (_bounds && redrowappData) {
        for (var i = 0; i < redrowappData.length; i++) {
          var marker = redrowappData[i];

          if (
            marker["development"]["latitude"] &&
            marker["development"]["longitude"]
          ) {
            if (
              _bounds.contains({
                lat: marker["development"]["latitude"],
                lng: marker["development"]["longitude"],
              }) === true
            ) {
              _filteredPlots.push(marker);
            }
          }
        }
      }

      setZoomedDevelopments(_filteredPlots);
    }
  };

  const onDragFinished = () => {
    if (map) {
      var _filteredPlots = [];
      const _bounds = map.getBounds();

      if (_bounds && redrowappData) {
        for (var i = 0; i < redrowappData.length; i++) {
          var marker = redrowappData[i];

          if (
            marker["development"]["latitude"] &&
            marker["development"]["longitude"]
          ) {
            if (
              _bounds.contains({
                lat: marker["development"]["latitude"],
                lng: marker["development"]["longitude"],
              }) === true
            ) {
              _filteredPlots.push(marker);
            }
          }
        }
      }

      setZoomedDevelopments(_filteredPlots);
    }
  };

  return isLoaded ? (
    <div className={styles.Container}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoom_changed: () => {
            zoomChanged(map);
          },
        }}
        zoom={5}
        disableDefaultUI={true}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDragEnd={() => onDragFinished()}
      >
        <>
          <MarkerClusterer
            options={clusterOptions}
            minimumClusterSize={3}
            ignoreHidden={true}
          >
            {(clusterer) => devMarkers(clusterer)}
          </MarkerClusterer>
        </>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapContainer);
